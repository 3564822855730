/* eslint-disable react/no-unescaped-entities */
import React, { useState }  from 'react';
import { useFormik } from 'formik';
import * as yup from 'yup';
import PhoneInput from 'react-phone-number-input';
import { useTheme } from '@mui/material/styles';
import ReCAPTCHA from 'react-google-recaptcha';
import { Box } from '@mui/material';
import { Grid } from '@mui/material';
import { Typography } from '@mui/material';
import { TextField } from '@mui/material';
import { Button } from '@mui/material';
import { Divider } from '@mui/material';
import { navigate } from 'gatsby';
import FileUploadViewer from 'views/NotFoundCover/FileUploadViewer';
import MenuItem from '@mui/material/MenuItem';

const validationSchema = yup.object({
  firstName: yup
    .string()
    .trim()
    .min(2, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your first name'),
  lastName: yup
    .string()
    .trim()
    .min(1, 'Please enter a valid name')
    .max(50, 'Please enter a valid name')
    .required('Please specify your last name'),
  email: yup
    .string()
    .trim()
    .email('Please enter a valid email address')
    .required('Email is required.'),
    contactNumber: yup
    .string()
    .trim()
    .required('please enter phone number')
    .matches(
      /^(?:(?:\+|0{0,2})91(\s*|[ -])?|[0]?)?([6789]\d{2}([ -]?)\d{3}([ -]?)\d{4})$/,
      'Please enter a valid phone number.'
    ),
  message: yup
    .string()
    .trim()
    .required('Please specify your message'),
});

const axios = require('axios').default;
let isDisabled = true;
const Hero = () => {
  
  const [captchaCode, setCaptchaCode] = useState('');
  const theme = useTheme();

  const initialValues = {
    firstName: '',
    lastName: '',
    email: '',
    contactNumber: '',
    query_type: 'contact',
    message: '',
    Board: '',
    DOB: '',
    AGE: '',
    Location: '',
    Qualification:'',
    schoolname:'',
    Subject: '',
    classes: '',
    schoolname1:'',
    Subject1: '',
    classes1: '',
    Board1: '',
  };

  const onSubmit = () => {
    axios
    .post('https://9j1kup2g1g.execute-api.ap-south-1.amazonaws.com/apiv1/sendemail', formik.values)
   
  

    return formik.values;
  };

  const Wredirect = () => {
    axios
    .post('https://9j1kup2g1g.execute-api.ap-south-1.amazonaws.com/apiv1/sendemail', formik.values)
   {
    globalThis.window.location = new URL(
      window.location.origin +
        `/thanks-for-submission`,
    ); 
    }
}

  const onChange = (valueCaptcha) => {
    setCaptchaCode(valueCaptcha.length);
  };

  const formik = useFormik({
    initialValues,
    validationSchema: validationSchema,
    onSubmit,
  });

  if (
    formik &&
    formik.values &&
    Object.keys(formik.errors).length === 0 &&
    captchaCode
  ) {
    isDisabled = false;
  }

  const handleUpdateFileInfo = (file, fileName, imagePreviewUrl) => {
    formik.values.photo = file;
    formik.values.fileName = fileName;
    formik.values.imagePreviewUrl = imagePreviewUrl;
   
  };


  return (
    <Box maxWidth={800} margin={'0 auto'}>
      <Box marginBottom={4}>
        <Typography
          variant={'h4'}
          sx={{ fontWeight: 700 }}
          align={'center'}
          gutterBottom
        >
          Enquiry form for teaching staff
        </Typography>
      </Box>
      <Box>
        
          <Grid container spacing={4}>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="First name"
                variant="outlined"
                color="primary"
                size="medium"
                name="firstName"
                fullWidth
                defaultValue={formik.values.firstName}
                onChange={formik.handleChange('firstName')}
                error={
                  formik.touched.firstName || Boolean(formik.errors.firstName)
                }
                helperText={formik.touched.firstName || formik.errors.firstName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Last name"
                variant="outlined"
                color="primary"
                size="medium"
                name="lastName"
                fullWidth
                defaultValue={formik.values.lastName}
                onChange={formik.handleChange('lastName')}
                error={
                  formik.touched.lastName || Boolean(formik.errors.lastName)
                }
                helperText={formik.touched.lastName || formik.errors.lastName}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Email"
                type="email"
                variant="outlined"
                color="primary"
                size="medium"
                name="email"
                fullWidth
                defaultValue={formik.values.email}
                onChange={formik.handleChange('email')}
                error={formik.touched.email || Boolean(formik.errors.email)}
                helperText={formik.touched.email || formik.errors.email}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Phone number"
                type="contactNumber"
                variant="outlined"
                color="primary"
                size="medium"
                name="contactNumber"
                fullWidth
                defaultValue={formik.values.contactNumber}
                onChange={formik.handleChange('contactNumber')}
                error={formik.touched.contactNumber || Boolean(formik.errors.contactNumber)}
                helperText={formik.touched.contactNumber || formik.errors.contactNumber}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="DOB"
                type="DOB"
                variant="outlined"
                color="primary"
                size="medium"
                name="DOB"
                fullWidth
                defaultValue={formik.values.DOB}
                onChange={formik.handleChange('DOB')}
                error={formik.touched.DOB || Boolean(formik.errors.DOB)}
                helperText={formik.touched.DOB || formik.errors.DOB}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="AGE"
                type="AGE"
                variant="outlined"
                color="primary"
                size="medium"
                name="AGE"
                fullWidth
                defaultValue={formik.values.AGE}
                onChange={formik.handleChange('AGE')}
                error={formik.touched.AGE || Boolean(formik.errors.AGE)}
                helperText={formik.touched.AGE || formik.errors.AGE}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Total No Experiace"
                type="Experiace"
                variant="outlined"
                color="primary"
                size="medium"
                name="Experiace"
                fullWidth
                defaultValue={formik.values.Experiace}
                onChange={formik.handleChange('Experiace')}
                error={formik.touched.Experiace || Boolean(formik.errors.Experiace)}
                helperText={formik.touched.Experiace || formik.errors.Experiace}
              />
            </Grid><Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Current Location"
                type="Location"
                variant="outlined"
                color="primary"
                size="medium"
                name="Location"
                fullWidth
                defaultValue={formik.values.Location}
                onChange={formik.handleChange('Location')}
                error={formik.touched.Location || Boolean(formik.errors.Location)}
                helperText={formik.touched.Location || formik.errors.Location}
              />
            </Grid>   
            
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Qualification"
                type="Qualification"
                variant="outlined"
                color="primary"
                size="medium"
                name="Qualification"
                fullWidth
                defaultValue={formik.values.Qualification}
                onChange={formik.handleChange('Qualification')}
                error={formik.touched.Qualification || Boolean(formik.errors.Qualification)}
                helperText={formik.touched.Qualification || formik.errors.Qualification}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
            <TextField
              select
              label="BEd"
              variant="outlined"
              name={'BEd'}
              fullWidth
              defaultValue={formik.values.BEd}
              onChange={formik.handleChange('BEd')}
              error={
                formik.touched.BEd || Boolean(formik.errors.BEd)
              }
              helperText={formik.touched.BEd || formik.errors.BEd}
            >
              {['Yes', 'No'].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </TextField>
          </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' fontWeight={800} align='center'>
                <br/> PRESENT JOB PROFILE <br/>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Name of the School"
                type="schoolname"
                variant="outlined"
                color="primary"
                size="medium"
                name="schoolname"
                fullWidth
                defaultValue={formik.values.schoolname}
                onChange={formik.handleChange('schoolname')}
                error={formik.touched.schoolname || Boolean(formik.errors.schoolname)}
                helperText={formik.touched.schoolname || formik.errors.schoolname}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Board"
                type="Board"
                variant="outlined"
                color="primary"
                size="medium"
                name="Board"
                fullWidth
                defaultValue={formik.values.Board}
                onChange={formik.handleChange('Board')}
                error={formik.touched.Board || Boolean(formik.errors.Board)}
                helperText={formik.touched.Board || formik.errors.Board}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Subject Handeled"
                type="Subject"
                variant="outlined"
                color="primary"
                size="medium"
                name="Subject"
                fullWidth
                defaultValue={formik.values.Subject}
                onChange={formik.handleChange('Subject')}
                error={formik.touched.Subject || Boolean(formik.errors.Subject)}
                helperText={formik.touched.Subject || formik.errors.Subject}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="classes Handeled"
                type="classes"
                variant="outlined"
                color="primary"
                size="medium"
                name="classes"
                fullWidth
                defaultValue={formik.values.classes}
                onChange={formik.handleChange('classes')}
                error={formik.touched.classes || Boolean(formik.errors.classes)}
                helperText={formik.touched.classes || formik.errors.classes}
              />
            </Grid>

            <Grid item xs={12}>
              <TextField
                label="period of service"
                multiline
                rows={3}
                variant="outlined"
                color="primary"
                size="medium"
                name="message"
                fullWidth
                defaultValue={formik.values.message}
                onChange={formik.handleChange('message')}
                error={formik.touched.message || Boolean(formik.errors.message)}
                helperText={formik.touched.message || formik.errors.message}
              />

            </Grid>
            <Grid item xs={12}>
              <Typography variant='h6' fontWeight={800} align='center'>
                <br/> PAST EXPERIENCE DETAILS<br/>
              </Typography>
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Name of the School"
                type="schoolname1"
                variant="outlined"
                color="primary"
                size="medium"
                name="schoolname1"
                fullWidth
                defaultValue={formik.values.schoolname1}
                onChange={formik.handleChange('schoolname1')}
                error={formik.touched.schoolname1 || Boolean(formik.errors.schoolname1)}
                helperText={formik.touched.schoolname1 || formik.errors.schoolname1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Board"
                type="Board1"
                variant="outlined"
                color="primary"
                size="medium"
                name="Board1"
                fullWidth
                defaultValue={formik.values.Board1}
                onChange={formik.handleChange('Board1')}
                error={formik.touched.Board1 || Boolean(formik.errors.Board1)}
                helperText={formik.touched.Board1 || formik.errors.Board1}
              />
            </Grid>
            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="Subject Handeled"
                type="Subject1"
                variant="outlined"
                color="primary"
                size="medium"
                name="Subject1"
                fullWidth
                defaultValue={formik.values.Subject1}
                onChange={formik.handleChange('Subject1')}
                error={formik.touched.Subject1 || Boolean(formik.errors.Subject1)}
                helperText={formik.touched.Subject1 || formik.errors.Subject1}
              />
            </Grid>

            <Grid item xs={12} sm={6}>
              <TextField
                sx={{ height: 54 }}
                label="classes Handeled"
                type="classes1"
                variant="outlined"
                color="primary"
                size="medium"
                name="classes1"
                fullWidth
                defaultValue={formik.values.classes1}
                onChange={formik.handleChange('classes1')}
                error={formik.touched.classes1 || Boolean(formik.errors.classes1)}
                helperText={formik.touched.classes1 || formik.errors.classes1}
              />
            </Grid>
            {/* <Grid item xs={10}>
            <Typography
              variant={'subtitle2'}
              sx={{ marginBottom: 2 }}
              fontWeight={700}
            >
              Upload Resume*
            </Typography>

            <FileUploadViewer
              file={formik.values.photo}
              info={`stepone`}
              fileName={formik.values.fileName}
              imagePreviewUrl={formik.values.imagePreviewUrl}
              handleUpdateFileInfo={handleUpdateFileInfo}
            />
          </Grid> */}
          <Grid item xs={11}>
          <Typography align='justify' fontWeight={700}>
            <br/>
          “Mail your resume at <a href = " mailto:info@npsypr.edu.in" >info@npsypr.edu.in </a> or post it to our address. Resume should be accompanied
with handwritten write up on” What strategies will you employ to make your subject interesting to
students " or "How will you contribute to the child's holistic development" and recent passport
size coloured photograph.”


          </Typography></Grid>
            <br />
            <br />
            <Grid item container justifyContent={'center'} xs={12} >
            <Box
            alignContent={'center'}>
               <ReCAPTCHA
                  sitekey="6LdnjA8iAAAAAL0iFw5FQzAk1rnONk2acKVt19a-"
                  onChange={onChange}
                />
            </Box>
            </Grid>
            <br />
            <Grid item container justifyContent={'center'} xs={12} >
              <Button
                component={Button}
                variant="contained"
                color="primary"
                size="large"
                type="submit"
                height={54}
                marginTop={{ xs: 4, sm: 4 }}
                marginLeft={{ xs: 15, sm: 38 }}
                disabled={isDisabled}
                readOnly={isDisabled}
                onClick={onSubmit && Wredirect}
              >
                Submit
              </Button>
            </Grid>
            
            <Grid item container justifyContent={'center'} xs={12}>
              <Typography color="text.secondary">
              Please submit your Form
              </Typography>
            </Grid>
            <Grid item xs={12}>
              <Divider />
            </Grid>
            <Grid item container justifyContent={'center'} xs={12}>
              <Box>
                <Typography component="p" variant="body2" align="left">
                  By clicking on "submit" you agree to our{' '}
                  <Box
                    component="a"
                    href="/privacy-and-policy"
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Privacy Policy
                  </Box>
                  ,{' '}
                  <Box
                    component="a"
                    href="/terms-and-conditions"
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Terms & Conditions 
                  </Box>{' '}
                  and{' '}
                  <Box
                    component="a"
                    href="/cancellation-policy"
                    color={theme.palette.text.primary}
                    fontWeight={'700'}
                  >
                    Refund Policy
                  </Box>
                  .
                </Typography>
              </Box>
            </Grid>
           
          </Grid>
      </Box>
    </Box>
  );
};

export default Hero;